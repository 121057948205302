import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './VideoCarousel.css';

const VideoCarousel = ({ videos }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const nextVideo = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    }
  };

  const prevVideo = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
    }
  };

  useEffect(() => {
    if (isTransitioning) {
      const timer = setTimeout(() => setIsTransitioning(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isTransitioning]);

  return (
    <div className="video-carousel">
      <div className={`video-container ${isTransitioning ? 'transitioning' : ''}`}>
        {videos.map((video, index) => (
          <div
            key={index}
            className={`video-slide ${index === currentVideoIndex ? 'active' : ''}`}
            style={{
              transform: `translateX(${(index - currentVideoIndex) * 100}%)`,
              transition: isTransitioning ? 'transform 0.5s ease' : 'none',
            }}
          >
            <ReactPlayer
              url={video}
              playing={index === currentVideoIndex && !isMobile}
              controls={true}
              width="100%"
              height="100%"
              muted={true}
            />
          </div>
        ))}
      </div>
      <div className="carousel-controls">
        <button className="prev-button" onClick={prevVideo} disabled={isTransitioning}>&lt;</button>
        <button className="next-button" onClick={nextVideo} disabled={isTransitioning}>&gt;</button>
      </div>
    </div>
  );
};

export default VideoCarousel;