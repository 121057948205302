import React from 'react';
import { Container } from 'react-bootstrap';
import { FaArrowRight } from 'react-icons/fa';

// Blog data
const blogPosts = [
  {
    id: 1,
    title: 'Nile Valley trading & Agencies',
    description: 'Nile Valley trading & Agencies is a leading company in the field of importing and exporting food products, and it is one of the largest companies in the field of importing and exporting food products in Egypt and the Middle East',
    image: 'img/Logos/nvt2.png',
    category: 'Trading',

    url: '/nvt',
    color: '#f3f3f3' // Light red color
  },
  {
    id: 2,
    title: 'Nile Valley Seeds',
    description: 'Nile Valley Seeds is a leading company in the field of importing and exporting seeds, and it is one of the largest and most respected companies in the field of importing and exporting seeds in Egypt and the Middle East',
    image: 'img/Logos/NVSLogo2.png',
    category: 'Agriculture',
    
    url: '/nvs',
    color: '#f3f3f3' // Light green color
  },
  {
    id: 3,
    title: 'PACKAD',
    description: 'PACKAD is a leading company in the field of importing and exporting packaging materials, and it is one of the largest companies in the field of importing and exporting packaging materials in Egypt and the Middle East',
    image: 'img/Logos/packad.png',
    category: 'Packaging',
   
    url: '/packad',
    color: '#f3f3f3' // Light blue color
  }
];

const Blog = () => {
  return (
    <Container fluid className="py-5">
      <Container className="py-5">
        <div className="section-title">
          <h4>Check</h4>
          <h1>Our Companies</h1>
          {/* <p>NV Group proudly presents their companies</p> */}
        </div>
        <div className="row g-4 justify-content-center">
          {blogPosts.map(post => (
            <div className="col-lg-6 col-xl-4" key={post.id}>
              <div className="blog-item">
                <div className="blog-img">
                  <img src={post.image} className="p-5" alt={`Blog ${post.id}`} loading="lazy"/>
                  <div className="blog-category">
                    <span>{post.category}</span>
                  </div>
                </div>
                <div className="blog-content" style={{ backgroundColor: post.color }}>
                  
                  <a href={post.url} className="h4 d-inline-block mb-3" style={{ textDecoration: 'none' }}>{post.title}</a>
                  <p className="mb-3">{post.description}</p>
                  <a href={post.url} className="btn">Visit Website<FaArrowRight /></a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </Container>
  );
};

export default Blog;
