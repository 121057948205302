import React from 'react';
import Carousel from './nvtComponents/Carousel';
import About from './nvtComponents/About';
import Features from './nvtComponents/Features';
// import Team from './nvtComponents/Team';
import OurPartners from './nvtComponents/OurPartners';
import { useOutletContext } from 'react-router-dom';
import { ProductList } from './nvtComponents/ProductList';

function NvtHome() {
    const scrollRefs = useOutletContext();
    return (
        <>
             <div ref={scrollRefs.homeRef}><Carousel /></div>
             <div ref={scrollRefs.aboutRef}><About /></div>
            {/* <Services /> */}
            {/* <div ref ={scrollRefs.featuresRef}><Features /></div> */}
            <div ref = {scrollRefs.partnersRef}><OurPartners/></div>
            {/* <Team /> */}
            <div ref={scrollRefs.productsRef}><ProductList/></div>
          
         
           
        </>
    );
}

export default NvtHome;