import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Partners = () => {
    const [position, setPosition] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    const partners = [
        // {
        //     name: 'AWETA',
        //     logo: "img/NVTagents/agent1.webp",
        //     description: 'Founded in 1966, Aweta is a Dutch company that specializes in designing and manufacturing sorting and packing solutions for the fresh produce industry. Known for its innovative technology, Aweta serves global clients by ensuring precise sorting based on size, color, and internal quality.'
        // },
        {
            name: 'Haith',
            logo: "img/NVTagents/agent2.webp",
            description: 'Haith Group, established in 1964, is a UK-based leader in the manufacturing of vegetable handling, washing, and packaging equipment. They provide tailored solutions for farmers and food processors, known for reliability and innovation in agricultural machinery.'
        },
        {
            name: 'Gunther Packaging',
            logo: "img/NVTagents/agent3.webp",
            description: 'Gunther Packaging Systems is a global leader in providing packaging solutions for the food industry, known for enhancing shelf life and maintaining the freshness of packaged products with a wide range of machinery.'
        },
        {
            name: 'Noveltek',
            logo: "img/NVTagents/agent4.webp",
            description: 'Noveltek Industrial Manufacturing Inc., founded in 1990 in Taiwan, is a prominent manufacturer of material handling equipment, including electric forklifts, pallet trucks, and stackers, used in various industries worldwide.'
        },
        {
            name: 'NOVAPACK SÜD',
            logo: "img/NVTagents/agent5.webp",
            description: 'NOVAPACK SÜD specializes in designing and manufacturing eco-friendly packaging solutions for the cosmetics and pharmaceutical industries, offering innovative products that meet the highest industry standards.'
        },
        {
            name: 'TECO',
            logo: "img/NVTagents/agent6.webp",
            description: 'TECO Electric & Machinery Co., Ltd., based in Taiwan and founded in 1956, is a global leader in producing industrial motors and drives, widely used in industrial automation and energy-saving applications.'
        },
        {
            name: 'Manter',
            logo: "img/NVTagents/agent7.webp",
            description: 'Manter International B.V. is a Dutch company specializing in weighing and packaging machines for the fresh produce industry, known for precision, durability, and user-friendly operation.'
        },
        {
            name: 'International Paper',
            logo: "img/NVTagents/agent9.webp",
            description: 'International Paper is a global leader in packaging, pulp, and paper products, headquartered in Memphis, Tennessee. Established in 1898, the company serves customers in over 150 countries with renewable fiber-based solutions.'
        },
        {
            name: 'PROTEKU',
            logo: "img/NVTagents/agent10.webp",
            description: 'PROTEKU provides innovative protective packaging solutions, focusing on customized options that ensure the safety and integrity of products during transportation and storage.'
        },
        {
            name: 'SENSITECH',
            logo: "img/NVTagents/agent11.webp",
            description: 'Sensitech Inc. is a global leader in cold chain visibility solutions, offering real-time monitoring systems to ensure the quality and safety of temperature-sensitive products, especially in the pharmaceutical and food industries.'
        },
    ];
    

    const duplicatedPartners = [...partners, ...partners];

    useEffect(() => {
        const interval = setInterval(() => {
            setPosition((prevPosition) => {
                const maxScrollWidth = -(partners.length * 280);
                return prevPosition > maxScrollWidth ? prevPosition - 1 : 0;
            });
        }, isHovering ? 50 : 20);

        return () => clearInterval(interval);
    }, [partners.length, isHovering]);

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    return (
        <Container fluid className="py-5 bg-light">
            <Row className="text-center mb-5">
                <Col>
                    <h2 className="display-4 fw-bold">Our Partners</h2>
                    <p className="lead text-muted">Collaborating with industry leaders to drive innovation</p>
                </Col>
            </Row>
            <div className="partner-carousel-container position-relative overflow-hidden">
                <div
                    className="partner-carousel d-flex"
                    style={{
                        transform: `translateX(${position}px)`,
                        transition: 'transform 0.2s linear',
                        width: `${duplicatedPartners.length * 280}px`,
                    }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    {duplicatedPartners.map((partner, index) => (
                        <div
                            key={index}
                            className="partner-card text-center bg-white shadow-sm rounded mx-2"
                            style={{
                                minWidth: '280px',
                                padding: '20px',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            <img
                                src={partner.logo}
                                alt={partner.name}
                                className="mb-3"
                                style={{
                                    width: '100px',
                                    height: '100px',
                                    objectFit: 'contain',
                                }}
                            />
                            <h5 className="fw-bold">{partner.name}</h5>
                            <p className="text-muted">{partner.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default Partners;
