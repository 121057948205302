import {React  ,useRef } from "react";
import { Outlet } from 'react-router-dom';
import Header from './nvtComponents/Header';
import Footer from './nvtComponents/Footer';

function NvtMain() {
    const aboutRef = useRef(null);
    const homeRef = useRef(null);
    const partnersRef = useRef(null);
    const featuresRef = useRef(null);
    const productsRef = useRef(null);

    const scrollRefs = {
        homeRef,
        aboutRef,
        featuresRef,
        partnersRef,
        productsRef
    };
    const scrollToSection = (elementRef) => {
        if (elementRef && elementRef.current) {
            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <Header scrollRefs={scrollRefs} scrollToSection={scrollToSection} />
            <Outlet context={scrollRefs}></Outlet>
            <Footer />
        </>
    );
}

export default NvtMain;