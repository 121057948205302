import React from 'react';

const About = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden rounded ps-5 pt-5 h-100"
              style={{ minHeight: '400px' }}
            >
              <img
                className="position-absolute w-100 h-100"
                src="img/NVGrouppics/aboutImage3.jpeg"
                alt="About Us"
                loading="lazy"
                style={{ objectFit: 'cover' }}
              />
              <div
                className="position-absolute top-0 start-0 bg-white rounded pe-3 pb-3"
                style={{ width: '200px', height: '200px' }}
              >
                <div
                  className="d-flex flex-column justify-content-center text-center bg-primary rounded h-100 p-3"
                >
                  <h1 className="text-white mb-0">20+</h1>
                  <h2 className="text-white">Years</h2>
                  <h5 className="text-white mb-0">Experience</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="h-100">
              <h1 className="display-6 mb-5">About NV Group</h1>
              <p className="fs-5 text-primary mb-4">
                Excellence in innovation, quality, and service across industries.
              </p>
              <p>
                NV Group is a dynamic and diversified conglomerate headquartered in Egypt, with a strong presence in the Middle East and beyond. Our group is comprised of three key entities: Nile Valley Trade & Agencies (NVT), PACKAD, and Nile Valley Seeds (NVS). Together, these companies form a cohesive network, delivering exceptional products and services across various industries.
              </p>
              <p>
                NV Group is committed to excellence, innovation, and growth. With a strong foundation and a clear vision for the future, we continue to expand our reach and enhance our offerings, delivering value to our clients, partners, and stakeholders across the globe.
              </p>
              <div className="row g-4 mb-4">
                <div className="col-sm-6">
                  <div className="d-flex align-items-center">
                    <img
                      className="flex-shrink-0 me-3"
                      src="img/icon/icon-04-primary.png"
                      alt=""
                    />
                    <h5 className="mb-0">Experts in the field</h5>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="d-flex align-items-center">
                    <img
                      className="flex-shrink-0 me-3"
                      src="img/icon/icon-03-primary.png"
                      alt=""
                    />
                    <h5 className="mb-0">Guaranteed Financial Outcomes</h5>
                  </div>
                </div>
              </div>
              <p>
                Our commitment to innovation, quality, and excellence has earned us a reputation as a leader in our respective industries. We continuously invest in research and development to stay ahead of the curve and provide our clients with cutting-edge solutions.
              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;