import React, { useRef, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';

const VideoPlayer = ({ videoSrc }) => {
  const playerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.5 } // Trigger when 50% of the component is visible
    );

    if (playerRef.current) {
      observer.observe(playerRef.current);
    }

    return () => {
      if (playerRef.current) {
        observer.unobserve(playerRef.current);
      }
    };
  }, []);

  return (
    <div className="video-player-container py-5" ref={playerRef}>
      <div className="video-card">
        <ReactPlayer
          url={videoSrc}
          playing={isVisible}
          controls={true}
          width="100%"
          height="auto"
          muted={true} // Mute the video to allow autoplay
        />
      </div>
    </div>
  );
};

export default VideoPlayer;