import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

function OurCertifications() {
    const [position, setPosition] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    const certifications = [
        { name: 'Partner 1', logo: "img/NVTagents/cert1.webp" },
        { name: 'Partner 2', logo: "img/NVTagents/cert2.webp" },
        { name: 'Partner 3', logo: "img/NVTagents/cert3.webp" },
        { name: 'Partner 4', logo: "img/NVTagents/cert4.webp" },
        { name: 'Partner 5', logo: "img/NVTagents/cert5.webp" },
        { name: 'Partner 6', logo: "img/NVTagents/cert6.webp" },
    
        
    ];

    const duplicatedCertifications = [...certifications, ...certifications];

    useEffect(() => {
        const interval = setInterval(() => {
            setPosition((prevPosition) => {
                const maxScrollWidth = -(certifications.length * 230);
                return prevPosition > maxScrollWidth ? prevPosition - 1 : 0;
            });
        }, isHovering ? 50 : 20);

        return () => clearInterval(interval);
    }, [certifications.length, isHovering]);

    const handleMouseEnter = () => setIsHovering(true);
    const handleMouseLeave = () => setIsHovering(false);

    return (
        <Container fluid className="py-5 bg-light">
            <Row className="text-center mb-5">
                <Col>
                    <h2 className="display-4 fw-bold">Our Certifications</h2>
                    <p className="lead">Proudly holding industry-recognized certifications</p>
                </Col>
            </Row>
            <div className="certification-carousel-container" style={{ position: 'relative' }}>
                <div className="certification-carousel-overlay"></div>
                <div
                    className="certification-carousel"
                    style={{
                        display: 'flex',
                        transform: `translateX(${position}px)`,
                        transition: 'transform 0.2s linear',
                        width: `${duplicatedCertifications.length * 230}px`
                    }}
                >
                    {duplicatedCertifications.map((certification, index) => (
                        <div
                            key={index}
                            className="certification-logo-container"
                            style={{ minWidth: '230px' }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Image
                                src={certification.logo}
                                alt={certification.name}
                                fluid
                                className="certification-logo"
                            />
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default OurCertifications;
