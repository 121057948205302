import React from "react";

export const ProductList = ({ products }) => {
  // Separate products into two categories
  const westecProducts = products.filter(product => product.category === "Westec");
  const genomeSeedsProducts = products.filter(product => product.category === "Genome");

  return (
    <div className="products">
      {/* Render Westec Products */}
      {westecProducts.length > 0 && (
        <>
          <h2>Westec Products</h2>
          {westecProducts.map((product, index) => (
            <Product key={index} data={product} />
          ))}
        </>
      )}
      {/* Render Genome Seeds Products */}
      {genomeSeedsProducts.length > 0 && (
        <>
          <h2>Genome Seeds Products</h2>
          {genomeSeedsProducts.map((product, index) => (
            <Product key={index} data={product} />
          ))}
        </>
      )}
    </div>
  );
};

export const Product = (props) => {
  const { productName, productImage, subsections } = props.data;
  return (
    <div className="product">
      <img src={productImage} alt={productName} />
      <div className="description">
        <p>
          <b>{productName}</b>
        </p>
       
        {/* Render subsections */}
        {subsections && subsections.map((section, index) => (
          <div key={index} className="subsection">
            <h4 style={{ fontWeight: section.isBold ? "bold" : "normal" }}>
              {section.header}
            </h4>
            <p>{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};