import React from 'react';
import { FaLinkedin } from 'react-icons/fa';

const Team = () => {
  const teamMembers = [
    { 
      image: "img/NVGrouppics/Team-1.webp", 
      name: "Yasser Essam", 
      designation: "CEO and Founder", 
      delay: "0.1s",
      link: "https://www.linkedin.com/in/yasser-essam-8b4049262?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" 
    },
    { 
      image: "img/NVGrouppics/Team-2.webp", 
      name: "Iman Salah", 
      designation: "Board Member", 
      delay: "0.3s",
      link: "https://www.linkedin.com/in/eman-salah-00487041?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" // Replace with actual LinkedIn link
    }
    // Add additional team members if needed
  ];

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center mx-auto mb-5" style={{ maxWidth: "500px" }}>
          <h1 className="display-6">Meet Our Board</h1>
        </div>
        <div className="row g-4 justify-content-center">
          {teamMembers.map((member, index) => (
            <div key={index} className="col-lg-3 col-md-6 d-flex justify-content-center wow fadeInUp" data-wow-delay={member.delay}>
              <div className="team-item rounded d-flex flex-column align-items-center">
                <img className="img-fluid" src={member.image} alt={member.name} style={{ maxWidth: '100%', height: 'auto' }} />
                <div className="text-center p-4">
                  <h5>{member.name}</h5>
                  <span>{member.designation}</span>
                </div>
                <div className="team-text text-center bg-white p-4">
                  <h5>{member.name}</h5>
                  <p>{member.designation}</p>
                  <div className="d-flex justify-content-center">
                    <a className="btn btn-square btn-light m-1" href={member.link} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
