import React from 'react';
import { Nav, Navbar, Image, Container, Row, Col } from 'react-bootstrap';
import { FaClock, FaEnvelope, FaFacebook, FaLinkedinIn, FaTwitter, FaPhoneAlt, FaInstagram } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = ({ scrollToSection, scrollRefs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (path, ref) => {
    if (location.pathname === path) {
      scrollToSection(ref);
    } else {
      navigate(path);
      setTimeout(() => scrollToSection(ref), 100);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  return (
    <>
      <Container className="bg-dark text-white-50 py-2 px-0 d-none d-lg-block" fluid>
        <Row className="gx-0 align-items-center">
          <Col lg={7} className="px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
            <small className='d-flex'><FaPhoneAlt className='me-2' /></small>
            <small>+010 0098102</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center me-4">
            <small className='d-flex'><FaEnvelope className='me-2'/></small>
            <small>yessam@nvt.com.eg</small>
            </div>
            
          </Col>
          <Col lg={5} className="px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center">
              {/* <a className="text-white-50 ms-4 d-flex" href="#"><FaFacebook /></a>
              <a className="text-white-50 ms-4 d-flex" href="#"><FaTwitter /></a>
              <a className="text-white-50 ms-4 d-flex" href="#"><FaLinkedinIn /></a> */}
              <a className="text-white-50 ms-4 d-flex" href="https://www.instagram.com/nilevalleytrade?igsh=MWt2dHZ0dDZiMHdseg=="><FaInstagram /></a>
            </div>
          </Col>
        </Row>
      </Container>

      <Navbar expand="lg" bg="white" className='sticky-top px-4 px-lg-5' aria-label="Main Navigation">
        <Navbar.Brand className='d-flex align-items-center' href="/">
          <Image fluid src="/img/Logos/nvt.png" alt="NV GROUP Logo" className="me-3" />
          <h1 className="m-0">NV GROUP</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='mx-auto bg-light rounded pe-4 py-3 py-lg-0'>
            <Nav.Link className='nav-item' onClick={scrollToTop}>Home</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/', scrollRefs.aboutRef)}>About Us</Nav.Link>
            
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/',scrollRefs.teamRef)}>Our Board</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/', scrollRefs.companiesRef)}>Our Companies</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;
