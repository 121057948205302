import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Services = () => {
  return (
    <section id="ts-service-area" className="ts-service-area pb-0 mt-4">
      <Container>
        <Row className="text-center">
          <div className="col-12">
            <h2 className="section-title">We Are Specialists In</h2>
            <h3 className="section-sub-title">What We Do</h3>
          </div>
        </Row>
        {/* Title row end */}

        <Row>
          <Col lg={4}>
            <div className="ts-service-box d-flex">
              <div className="ts-service-box-img">
                <img loading="lazy" src="img/packad/service-icon1.png" alt="service-icon" />
              </div>
              <div className="ts-service-box-info">
                <h3 className="service-box-title"><a href="#">Advanced Factory</a></h3>
                <p>State-of-the-art facility for efficient and high-quality production</p>
              </div>
            </div>{/* Service 1 end */}

            <div className="ts-service-box d-flex">
              <div className="ts-service-box-img">
                <img loading="lazy" src="img/packad/service-icon2.png" alt="service-icon" />
              </div>
              <div className="ts-service-box-info">
                <h3 className="service-box-title"><a href="#">High-End Engineering</a></h3>
                <p>Precision-engineered packaging for maximum durability</p>
              </div>
            </div>{/* Service 2 end */}

            <div className="ts-service-box d-flex">
              <div className="ts-service-box-img">
                <img loading="lazy" src="img/packad/service-icon3.png" alt="service-icon" />
              </div>
              <div className="ts-service-box-info">
                <h3 className="service-box-title"><a href="#">Custom Designs</a></h3>
                <p>We create tailored cartons to meet specific client needs</p>
              </div>
            </div>{/* Service 3 end */}

          </Col>{/* Col end */}

          <Col lg={4} className="text-center">
            <img loading="lazy" className="img-fluid" src="img/packad/service-center.jpg" alt="service-avater-image" />
          </Col>{/* Col end */}

          <Col lg={4} className="mt-5 mt-lg-0 mb-4 mb-lg-0">
            <div className="ts-service-box d-flex">
              <div className="ts-service-box-img">
                <img loading="lazy" src="img/packad/service-icon4.png" alt="service-icon" />
              </div>
              <div className="ts-service-box-info">
                <h3 className="service-box-title"><a href="#">Versatile Solutions</a></h3>
                <p>We offer various packaging options for different industries</p>
              </div>
            </div>{/* Service 4 end */}

            <div className="ts-service-box d-flex">
              <div className="ts-service-box-img">
                <img loading="lazy" src="img/packad/service-icon5.png" alt="service-icon" />
              </div>
              <div className="ts-service-box-info">
                <h3 className="service-box-title"><a href="#">Fast Turnaround</a></h3>
                <p>Quick and efficient production</p>
              </div>
            </div>{/* Service 5 end */}

            <div className="ts-service-box d-flex">
              <div className="ts-service-box-img">
                <img loading="lazy" src="img/packad/service-icon6.png" alt="service-icon" />
              </div>
              <div className="ts-service-box-info">
                <h3 className="service-box-title"><a href="#">Safety Compliance</a></h3>
                <p>Our packaging meets strict safety standards</p>
              </div>
            </div>{/* Service 6 end */}
          </Col>{/* Col end */}
        </Row>{/* Content row end */}

      </Container>
      {/* Container end */}
      {/* Service end */}</section>
  );
};

export default Services;
