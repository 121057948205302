import React, { useRef } from "react";

import Footer from './nvsComponents/Footer';
import Header from './nvsComponents/Header';

import { Outlet } from 'react-router-dom';




function NvsMain() {
    const aboutRef = useRef(null);
    const homeRef = useRef(null);
    const servicesRef = useRef(null);
    const teamRef = useRef(null);

    const scrollRefs = {
        homeRef,
        aboutRef,
        servicesRef,
        teamRef
    };

    const scrollToSection = (elementRef) => {
        if (elementRef && elementRef.current) {
            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };

    
   
    return (
        <>
    
    <Header scrollRefs={scrollRefs} scrollToSection={scrollToSection} />
            <Outlet context={scrollRefs}></Outlet>
            <Footer />

     
        </>
    );
}

export default NvsMain;