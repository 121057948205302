import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';

const Facts = () => {
    useEffect(() => {
        // Step 1: Select all counterUp elements
        const counters = document.querySelectorAll('.counterUp');

        // Step 2: Define the count up function
        const countUp = (el) => {
            const target = el.getAttribute('data-count');
            const dataCount = parseInt(target, 10);
            const increment = dataCount / 200; // Adjust the speed as needed

            const updateCount = setInterval(() => {
                const value = parseInt(el.innerText, 10);
                if (value < dataCount) {
                    el.innerText = Math.ceil(value + increment);
                } else {
                    el.innerText = dataCount;
                    clearInterval(updateCount);
                }
            }, 1); // Adjust interval as needed
        };

        // Step 3: Create an Intersection Observer
        const observer = new IntersectionObserver((entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    countUp(entry.target);
                    observer.unobserve(entry.target); // Stop observing once the counter starts
                }
            });
        }, { threshold: 0.1 }); // Adjust threshold as needed

        // Step 4: Observe each counter
        counters.forEach(counter => {
            observer.observe(counter);
        });

        // Cleanup observer on unmount
        return () => {
            observer.disconnect();
        };
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <section id="facts" className="facts-area bg-dark p-5 px-0">
            <Container>
                <div className="facts-wrapper">
                    <Row className="justify-content-center">
                        <div className="col-md-3 col-sm-6 ts-facts">
                            <div className="ts-facts-img">
                                <img loading="lazy" src="img/packad/carton.png" alt="facts-img" />
                            </div>
                            <div className="ts-facts-content">
                                <h2 className="ts-facts-num"><span className="counterUp" data-count="1800">0</span>+</h2>
                                <h3 className="ts-facts-title">Total Projects</h3>
                            </div>
                        </div>{/*<!-- Col end --> */}

                        <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-sm-0">
                            <div className="ts-facts-img">
                                <img loading="lazy" src="img/packad/fact2.png" alt="facts-img" />
                            </div>
                            <div className="ts-facts-content">
                                <h2 className="ts-facts-num"><span className="counterUp" data-count="50">0</span>+</h2>
                                <h3 className="ts-facts-title">Staff Members</h3>
                            </div>
                        </div>{/*<!-- Col end --> */}

                        {/* <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                            <div className="ts-facts-img">
                                <img loading="lazy" src="img/packad/fact3.png" alt="facts-img" />
                            </div>
                            <div className="ts-facts-content">
                                <h2 className="ts-facts-num"><span className="counterUp" data-count="1600">0</span></h2>
                                <h3 className="ts-facts-title">Hours of Work</h3>
                            </div>
                        </div> */}
                        {/* <!-- Col end --> */}

                        <div className="col-md-3 col-sm-6 ts-facts mt-5 mt-md-0">
                            <div className="ts-facts-img">
                                <img loading="lazy" src="img/packad/fact4.png" alt="facts-img" />
                            </div>
                            <div className="ts-facts-content">
                                <h2 className="ts-facts-num"><span className="counterUp" data-count="10">0</span>+</h2>
                                <h3 className="ts-facts-title">Countries Experience</h3>
                            </div>
                        </div>{/*<!-- Col end --> */}

                    </Row> {/* Facts end */}
                </div>
                {/*/ Content row end */}
            </Container>
            {/*/ Container end */}
            {/* Facts end */}</section>
    );
};

export default Facts;