import React from 'react';

const Partners = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '500px' }}>
                    <p className="fs-5 fw-bold text-primary">Partners</p>
                    <h1 className="display-5 mb-5">Our Partners</h1>
                </div>
                <div className="row justify-content-center g-4">
                    <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                        <div className="service-item rounded d-flex h-100">
                            <div className="service-img rounded">
                                <img className="img-fluid" src="img/service-1.jpg" alt="" />
                            </div>
                            <div className="service-text rounded p-5">
                                <div className="btn-square rounded-circle mx-auto mb-3">
                                    <img className="img-fluid" src="img/nvs/Westec.webp" alt="Westec Logo" style={{width: '150px', height: '150px', objectFit: 'contain'}} />
                                </div>
                                <h4 className="mb-3">Westec Inc</h4>
                                <p className="mb-4">Westec Inc., founded in 1987 by agricultural experts in California, specializes in exporting non-GMO and hybrid seeds along with advanced U.S. farming technology to the Middle East and Africa. With exclusive rights to high-yield alfalfa and corn varieties</p>
                                {/* <a className="btn btn-sm" href=""><i className='d-flex'><FaPlus className='text-primary me-2' /></i>Read More</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                        <div className="service-item rounded d-flex h-100">
                            <div className="service-img rounded">
                                <img className="img-fluid" src="img/service-2.jpg" alt="" />
                            </div>
                            <div className="service-text rounded p-5">
                                <div className="btn-square rounded-circle mx-auto mb-3">
                                    <img className="img-fluid" src="img/nvs/Genome.webp" alt="Genome Logo" style={{width: '150px', height: '150px', objectFit: 'contain'}} />
                                </div>
                                <h4 className="mb-3">Genome Seeds</h4>
                                <p className="mb-4">Genome Seeds, a leading seed producer and plant breeder, excels in R&D, production, processing, and marketing of high-quality hybrid vegetable seeds with headquarters in Jordan, France and United States</p>
                                {/* <a className="btn btn-sm" href=""><i className='d-flex'><FaPlus className='text-primary me-2' /></i>Read More</a> */}
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners;