import React, { useState, useEffect } from 'react';
import { Nav, Navbar,Button } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowRight, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaTwitter } from 'react-icons/fa';


const Header = ({ scrollToSection, scrollRefs }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (path, ref) => {
    if (location.pathname === path) {
      scrollToSection(ref);
    } else {
      navigate(path);
      setTimeout(() => scrollToSection(ref), 100);
    }
  };



  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 200); // 200 milliseconds = 0.2 seconds
    return () => clearTimeout(timer);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  return (
    <>
      {/* Spinner Start */}
      {showSpinner && (
        <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status" style={{width: '3rem', height: '3rem'}}></div>
        </div>
      )}
      {/* Spinner End */}
      {/* Topbar Start */}
      <div className="container-fluid bg-dark text-light px-0 py-2">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <FaPhoneAlt className='me-2' />
              <span>+010 00098103</span>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <FaEnvelope className='me-2' />
              <span>mustafamh2003@yahoo.co.uk</span>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <span>Follow Us:</span>
              <a className="btn btn-link text-light" href="https://www.facebook.com/profile.php?id=100024564679403"><FaFacebookF /></a>
              {/* <a className="btn btn-link text-light" href="/"><FaTwitter /></a>
              <a className="btn btn-link text-light" href="/"><FaLinkedinIn /></a>
              <a className="btn btn-link text-light" href="/"><FaInstagram /></a> */}
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}
      {/* Navbar Start */}
      <Navbar bg="white" expand="lg" sticky="top" className="p-0">
      <Navbar.Brand href="/nvs" className="d-flex align-items-center px-4 px-lg-5">
        <img
          src="img/nvs/NVSLogo.webp"
          alt="NVS Logo"
          style={{ height: '80px', marginRight: '30px', marginBottom: '10px' }}
        />
        <h1 className="m-0">Nile Valley Seeds</h1>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarCollapse" className="me-4" />
      <Navbar.Collapse id="navbarCollapse">
        <Nav className="mx-auto pe-4 py-3 py-lg-0">
          <Nav.Link onClick={scrollToTop}>Home</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('/nvs', scrollRefs.aboutRef)}>About Us</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('/nvs', scrollRefs.servicesRef)}>Our Services</Nav.Link>
          <Nav.Link onClick={() => handleNavClick('/nvs', scrollRefs.teamRef)}>Our Team</Nav.Link>
        </Nav>
        <Button href="/" className="btn btn-primary py-4 px-lg-4 rounded-0 d-none d-lg-block">
          NV Group <FaArrowRight className='ms-3' />
        </Button>
      </Navbar.Collapse>
    </Navbar>
      {/* Navbar End */}
    </>
  );
};

export default Header;