import React from 'react';
import { FaCheck, FaTools, FaUsers } from 'react-icons/fa';

const AboutUs = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <p className="fs-5 fw-bold text-primary">About Us</p>
            <h1 className="display-5 mb-4">Why Choose Our Company</h1>
            <p>
            Nile Valley Seeds (NVS) is a specialized franchise under the NV Group, aligned with the global leader Genome. NVS focuses on the importation and distribution of a wide array of high-quality seeds, catering to the agricultural sector in Egypt. Despite being a relatively new entrant in the market, NVS has quickly established itself as a leading player, achieving rapid growth and recognition. Our success is driven by a commitment to innovation, quality, and customer satisfaction, positioning NVS as a trusted partner for farmers and agricultural businesses across the region
            </p>
          </div>
          <div className="col-lg-6">
            <div className="row g-4 align-items-center">
              <div className="col-md-6">
                <div className="row g-4">
                  <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                    <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                      <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: '90px', height: '90px' }}>
                        <FaCheck className="text-primary" size={48} />
                      </div>
                      <h4 className="mb-0">100% Satisfaction</h4>
                    </div>
                  </div>
                  <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                    <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                      <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: '90px', height: '90px' }}>
                        <FaUsers className="text-primary" size={48} />
                      </div>
                      <h4 className="mb-0">Dedicated Team</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
                <div className="text-center rounded py-5 px-4" style={{ boxShadow: '0 0 45px rgba(0,0,0,.08)' }}>
                  <div className="btn-square bg-light rounded-circle mx-auto mb-4" style={{ width: '90px', height: '90px' }}>
                    <FaTools className="text-primary" size={48} />
                  </div>
                  <h4 className="mb-0">24/7 Support</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;