import React from "react";
import { PRODUCTS } from "./nvsProducts"; // Adjust the path as needed
import { Product } from "./Product";

export const ProductList = () => {
  // Separate products into two categories
  const westecProducts = PRODUCTS.filter(product => product.category === "Westec");
  const genomeSeedsProducts = PRODUCTS.filter(product => product.category === "Genome");

  return (
    
    <div className="product-list-container">
      {/* Render Westec Products */}
      {westecProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header">Westec Products</h2>
          <div className="products">
            {westecProducts.map((product) => (
              <Product key={product.id} data={product} />
            ))}
          </div>
        </div>
      )}
      
      {/* Render Genome Seeds Products */}
      {genomeSeedsProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header">Genome Seeds Products</h2>
          <div className="products">
            {genomeSeedsProducts.map((product) => (
              <Product key={product.id} data={product} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
