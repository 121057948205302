import React from 'react';
import { Container, Carousel as BootstrapCarousel, Row, Col } from 'react-bootstrap';

const Carousel = () => {
  return (
    <Container fluid className="p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
      <BootstrapCarousel slide interval={5000} className='banner-carousel'>
        <BootstrapCarousel.Item style={{backgroundImage:"url(\"img/packad/carousel/carousel-1.jpeg\")"}}>
          <BootstrapCarousel.Caption>
            <Container className='text-end'>
              <Row className='align-items-center h-100'>
                <Col md={12}>
                  {/* <h2 className="display-3 mb-4 slide-title">
                  Meet Our Engineers
                  </h2> */}
                  <h3 className="slide-sub-title">
                  We believe sustainability
                  </h3>
                  <p>
                  We will deal with your failure that determines how you achieve success.
                  </p>
                  <a href="" className="slider btn btn-secondary py-2 px-3">
                    More Details
                  </a>
                  <a href="" className="slider btn btn-secondary text-uppercase border py-2 px-3 slideInLeft animated">
                    Learn More
                  </a>
                </Col>
              </Row>
            </Container>
          </BootstrapCarousel.Caption>
        </BootstrapCarousel.Item>
        <BootstrapCarousel.Item style={{backgroundImage:"url(\"img/packad/carousel/carousel-2.jpeg\")"}}>
          <BootstrapCarousel.Caption>
            <Container>
              <Row>
                <Col md={12} className='text-center'>
                <h2 className="display-3 mb-4 slide-title slideInLeft animated">
               
                  </h2>
                  <h3 className="slide-sub-title animated slideInRight">
                  State of the art machinery
                  </h3>
                  <a href="" className="slider btn btn-secondary text-uppercase py-2 px-3 slideInLeft animated">
                    Our Services
                  </a>
                  <a href="" className="slider btn btn-secondary text-uppercase border py-2 px-3 slideInLeft animated">
                    Contact Us
                  </a>
                </Col>
              </Row>
            </Container>
          </BootstrapCarousel.Caption>
        </BootstrapCarousel.Item>
      </BootstrapCarousel>
      <section className="call-to-action-box p-0">
        <Container>
          <div className="action-style-box">
            <div className="row align-items-center">
              <div className="col-md-8 text-center text-md-start">
                <div className="call-to-action-text">
                  <h3 className="action-title">We recognize and prioritize your packaging requirements</h3>
                </div>
              </div>
              <div className="col-md-4 text-center text-md-end mt-3 mt-md-0">
                {/* <div className="call-to-action-btn">
                  <a className="btn btn-dark text-uppercase px-3 py-2" style={{fontWeight:"bold"}} href="#">Request Quote</a>
                </div> */}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Container>
  );
};

export default Carousel;