import product1 from "../img/product-1.png";
import product2 from "../img/product-2.jpg";
import product3 from "../img/product-3.jpg";
import product4 from "../img/product-4.jpg";
import product5 from "../img/product-5.jpg";
import product6 from "../img/product-6.JPG";
import product7 from "../img/product-7.jpg";
import product8 from "../img/product-8.jpg";

export const PRODUCTS = [
  {
    id: 1,
    productName: "Alfafa",
    productImage: product1,
    category: "Westec",
    subsections: [
      {
        header: "Secret of Desert",
        // content: "High-quality feed, rich in nutrients.",
        isBold: true,
      },
      {
        header: "Cuf 101",
        // content: "Ideal for livestock feeding."
      }
    ]
  },
  {
    id: 3,
    productName: "Corn",
    productImage: product3,
    category: "Westec",
    subsections: [
      {
        header: "WH 5012",
        // content: "Excellent source of fiber.",
        isBold: true,
      },
      {
        header: "5411",
        // content: "Available year-round."
      },
      {
        header:"5015"
      },
      {
        header:"7117"
      }
    ]
  },
  {
    id: 2,
    productName: "Squash",
    productImage: product2,
    category: "Genome",
    subsections: [
      {
        header: "6900 LoaLoa",
        // content: "Rich in vitamins and minerals.",
        isBold: true,
      },
      {
        header: "Marriotte",
        // content: "Keep in a cool, dry place."
      }
    ]
  },
  {
    id: 4,
    productName: "Melon",
    productImage: product4,
    category: "Genome",
    subsections: [
      {
        header: "Ananas",
        content: "Fakher , Shatha",
       
      },
      {
        header: "Italian Type",
        content: "Gharam"
      }
    ]
  },
  {
    id: 5,
    productName: "Cantaloupe",
    productImage: product5,
    category: "Genome",
    subsections: [
      {
        header: "Mac G5",
        // content: "Sweet with a hint of musk.",
        isBold: true,
      },
      {
        header: "Dahab",
        // content: "Refrigerate after cutting."
      },
      {
        header: "G250",
      },
      {
        header: "G77",
      }
    ]
  },
  {
    id: 6,
    productName: "Eggplant",
    productImage: product6,
    category: "Genome",
    subsections: [
      {
        header: "Lamaz",
        // content: "Grill, roast, or fry.",
        isBold: true,
      },
      {
        header: "Jiana",
        // content: "Goes well with garlic and tomatoes."
      }
    ]
  },
  {
    id: 7,
    productName: "Cucumber",
    productImage: product7,
    category: "Genome",
    subsections: [
      {
        header: "Greenhouse",
        content: "1402,Alaa,Omega"
      },
      {
        header: "Openfield",
        content: "Bashayer,Baher,Rayhan"
      }
    ]
  },
  {
    id: 8,
    productName: "Tomato",
    productImage: product8,
    category: "Genome",
    subsections: [
      {
        header: "0666",
        // content: "Can be used in salads, sauces, and more.",
        isBold: true,
      },
      {
        header: "Marmar",
        // content: "Rich in vitamins C and K."
      },
      {
        header: "Dahab",
      }
    ]
  },
];
