import React from 'react';
import { FaTimes, FaUsers, FaPhone,FaSeedling } from 'react-icons/fa';
// this is to avoid deleteion
const TopFeat = () => {

 
  const features = [
    {
      icon: <FaSeedling className="text-primary" />,
      title: 'Wide Variety of Seeds',
      description: 'Choose from our certified organic and high quality seed options',
      delay: '0.1s'
    },
    {
      icon: <FaUsers className="text-primary" />,
      title: 'Dedicated Team',
      description: 'A dedicated team will be at your service',
      delay: '0.3s'
    },
    {
      icon: <FaPhone className="text-primary" />,
      title: '24/7 Available',
      description: 'Available 24/7 for your convenience',
      delay: '0.5s'
    }
  ];

  return (
    <div className="container-fluid top-feature py-5 pt-lg-0">
      <div className="container py-5 pt-lg-0">
        <div className="row gx-0">
          {features.map((feature, index) => (
            <div key={index} className="col-lg-4 wow fadeIn" data-wow-delay={feature.delay}>
              <div className="bg-white shadow d-flex align-items-center h-100 px-5" style={{minHeight: '160px'}}>
                <div className="d-flex">
                  <div className="flex-shrink-0 btn-lg-square rounded-circle bg-light">
                    {feature.icon}
                  </div>
                  <div className="ps-3">
                    <h4>{feature.title}</h4>
                    <span>{feature.description}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopFeat;