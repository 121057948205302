import React from 'react';

const MissionAndVision = () => {
  const missionPoints = [
    "Acting as the center and the real source of technical information and data and Statistics for our customers and our suppliers",
    "Providing our customers the responsive and effective service balance they expect and need for the products we supply, the service we provide and the prices we offer"
  ];

  const visionPoints = [
    "To be a globally respected organization which provide best packing and packaging solutions, using high-end technology and by best minds in the industry.",
    "To improve people's lives, the Neighboring environment and our company's performance by transforming renewable resources into products people depend on every day",
    "To be one of the most successful, sustainable and responsible companies in the world",
    "Building a Better Future for People, the Planet and Our Company"
  ];

  return (
    <div className="container-fluid bg-light about pb-5">
      <div className="container pb-5">
        <div className="row g-5">
          <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="about-item-content bg-white rounded p-5 h-100">
              <h4 className="text-primary">Our Mission</h4>
              <h2 className="display-4 mb-4">Leading with Expertise and Service Excellence</h2>
              <ul>
                {missionPoints.map((point, index) => (
                  <li key={`mission-${index}`}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
            <div className="about-item-content bg-white rounded p-5 h-100">
              <h4 className="text-primary">Our Vision</h4>
              <h2 className="display-4 mb-4">Innovating for a Sustainable Future</h2>
              <ul>
                {visionPoints.map((point, index) => (
                  <li key={`vision-${index}`}>{point}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionAndVision;