import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBinoculars, faPrint,faBuilding,faFlask,faHammer } from '@fortawesome/free-solid-svg-icons';

import './Facilities.css';
import { FaArrowRightLong } from 'react-icons/fa6';

const servicesData = [
  { 
    id: 1, 
    icon: faPrint, 
    title: 'Printing', 
   description:'Packad’s cutting-edge printing facility delivers high-quality, vibrant designs, ensuring precise and sustainable packaging solutions that enhance brand visibility',
    path: '/service-details'
  },
  { 
    id: 2, 
    icon: faHammer, 
    title: 'International Paper Erection Center', 
    description: 'Packad’s International Paper Erection Center ensures efficient and precise assembly of packaging systems, delivering reliable and high-quality installations',
    path: '/service-details'
  },
  
 
  { 
    id: 3, 
    icon: faFlask, 
    title: 'Lab', 
    description: 'Packad’s advanced lab offers precise analysis and testing, ensuring top-quality and reliable results for superior packaging solutions',
    path: '/service-details'
  }
  
];

const Facilities = () => {
  const navigate = useNavigate();

  return (
    <section id="services" className="services section-bg py-5">
      <Container>
        <div className="section-header text-center mb-5">
          <h2>Services</h2>
         </div>

        <Row className="gy-4">
          {servicesData.map((service) => (
            <Col lg={4} md={6} key={service.id} className="mb-4" data-aos="fade-up" data-aos-delay={`${(service.id - 1) * 100}`}>
              <div className="service-item position-relative p-4 border rounded bg-light">
                <div className="icon mb-3">
                  <FontAwesomeIcon icon={service.icon} size="3x" /> {/* Adjust the size as needed */}
                </div>
                <h3 className="mb-3">{service.title}</h3>
                <p className="mb-4">{service.description}</p>
                {/* <Button
                  variant="link"
                  className="readmore stretched-link p-0"
                  onClick={() => navigate(service.path)}
                >
                  Learn more <FaArrowRightLong />
                </Button> */}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Facilities;
