let currentLinkElement = null;

export function loadCssFile(href) {
  if (currentLinkElement) {
    document.head.removeChild(currentLinkElement);
  }

  const linkElement = document.createElement('link');
  linkElement.rel = 'stylesheet';
  linkElement.href = href;
  document.head.appendChild(linkElement);
  currentLinkElement = linkElement;
}
