import React from 'react';
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { FaBars, FaCommentDots, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = ({ scrollToSection, scrollRefs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (path, ref) => {
    if (location.pathname === path) {
      scrollToSection(ref);
    } else {
      navigate(path);
      setTimeout(() => scrollToSection(ref), 100);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  return (
    <>
      {/* Topbar Start */}
      <Container fluid className="topbar px-0 px-lg-4 bg-light py-2 d-none d-lg-block">
        <Container>
          <Row className="gx-0 align-items-center">
            <Col lg={8} className="text-center text-lg-start mb-lg-0">
              <div className="d-flex flex-wrap">
              
                <div className="ps-3">
                  <a href="eman@nvt.com.eg" className="text-muted small"><FaEnvelope className='text-primary me-2' />Email Us Now!</a>
                </div>
              </div>
            </Col>
            <Col lg={4} className="text-center text-lg-end">
              <div className="d-flex justify-content-end">
                <div className="d-flex border-primary pe-3">
                  {/* <a className="btn p-0 text-primary me-3" href="#"><FaFacebookF /></a>
                  <a className="btn p-0 text-primary me-3" href="#"><FaTwitter /></a> */}
                  <a className="btn p-0 text-primary me-3" href="https://www.instagram.com/nilevalleytrade?igsh=MWt2dHZ0dDZiMHdseg=="><FaInstagram /></a>
                  {/* <a className="btn p-0 text-primary me-0" href="#"><FaLinkedinIn /></a> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Topbar End */}

      {/* Navbar & Hero Start */}
      <Container fluid className="sticky-top nav-bar px-0 px-lg-4 py-lg-0">
        <Container>
          <Navbar expand="lg" className="navbar-light">
            <Navbar.Brand href='/nvt'>
              <h1 className="text-primary mb-0 d-flex align-items-center">
                <img src="/img/Logos/nvt.png" alt="Logo" style={{ width: '55px', height: '55px', objectFit: 'contain' }} />
                <span className="ms-2">NVT</span>
              </h1>
            </Navbar.Brand>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <FaBars />
            </button>
            <Navbar.Collapse id="navbarCollapse">
            <Nav className='mx-auto pe-4 py-3 py-lg-0'>
          <Nav.Link className='nav-item' onClick={scrollToTop}>Home</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/nvt', scrollRefs.aboutRef)}>About Us</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/nvt', scrollRefs.partnersRef)}>Our Partners</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/nvt', scrollRefs.productsRef)}>Products</Nav.Link>
            
          </Nav>
 
            </Navbar.Collapse>
            <div className="d-none d-xl-flex flex-shrink-0 ps-4">
              <a href="/" className="btn btn-light btn-lg-square rounded-circle position-relative wow tada" data-wow-delay=".9s">
                <FaPhoneAlt style={{fontSize:'2em'}}/>
                <div className="position-absolute" style={{ top: '7px', right: '12px' }}>
                  <span><FaCommentDots className='text-secondary' /> </span>
                </div>
              </a>
              <div className="d-flex flex-column ms-3">
                <span>Call Us!</span>
                <a href="tel:+20 1011518685"><span className="text-dark">Phone:+20 101 151 8685</span></a>
              </div>
            </div>
          </Navbar>
        </Container>
      </Container>
    </>
  );
};

export default Header;