import React from "react";
import { PRODUCTS } from "./nvtProducts"; // Adjust the path as needed
import { Product } from "./Product";

export const ProductList = () => {
  // Separate products into three categories
  const sensitechProducts = PRODUCTS.filter(product => product.category === "Sensitech");
  const protekuProducts = PRODUCTS.filter(product => product.category === "Proteku");
  const novaSudProducts = PRODUCTS.filter(product => product.category === "NovaPack Sud");

  return (
    <div className="product-list-container">
          <div style={{ paddingTop: '70px' }}></div>
      {/* Render Sensitech Products */}
      {sensitechProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header text-center">Sensitech Products</h2>
          <div className="products">
            {sensitechProducts.map((product) => (
              <Product key={product.id} data={product} />
            ))}
          </div>
        </div>
      )}

      {/* Render Proteku Products */}
      {protekuProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header text-center">Proteku Products</h2>
          <div className="products">
            {protekuProducts.map((product) => (
              <Product key={product.id} data={product} />
            ))}
          </div>
        </div>
      )}

      {/* Render NovaPack Sud Products */}
      {novaSudProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header text-center">NovaPackSüd Products</h2>
          <div className="products">
            {novaSudProducts.map((product) => (
              <Product key={product.id} data={product} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};