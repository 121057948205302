import React from 'react';
import { Link } from 'react-router-dom';
import { FaChevronRight, FaEnvelope,FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-light footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Our Office</h4>
            <p className="mb-2"><FaMapMarkerAlt className='me-3' />45 Industrial Zone Street, El Sadat City, Monufia Governorate, Egypt</p>
            <p className="mb-2"><FaPhoneAlt className='me-3' />+20 101 1518685</p>
            <p className="mb-2"><FaEnvelope className='me-3' />yessam@nvt.com.eg</p>
            <div className="d-flex pt-2">
                {/* <a className="btn btn-square btn-outline-light rounded-circle me-2 d-flex" href=""><FaTwitter/></a>
                <a className="btn btn-square btn-outline-light rounded-circle me-2 d-flex" href=""><FaFacebookF /></a>
                <a className="btn btn-square btn-outline-light rounded-circle me-2 d-flex" href=""><FaYoutube /></a> */}
              {/* <a className="btn btn-square btn-outline-light rounded-circle me-2 d-flex" href=""><FaLinkedinIn /></a> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Companies</h4>
            
            <Link className="btn btn-link" to="/">
              <FaChevronRight /> Visit NV Group
            </Link>
            
            {/* Use Link to navigate to /nvs */}
            <Link className="btn btn-link" to="/nvt">
              <FaChevronRight /> Visit NVT
            </Link>
            
            {/* Use Link to navigate to /packad */}
            <Link className="btn btn-link" to="/nvs">
              <FaChevronRight /> Visit NVS
            </Link>
            
            
        
          </div>
          {/* <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Quick Links</h4>
            <a className="btn btn-link" href=""><FaChevronRight/>About Us</a>
            <a className="btn btn-link" href=""><FaChevronRight/>Contact Us</a>
            <a className="btn btn-link" href=""><FaChevronRight/>Our Services</a>
            <a className="btn btn-link" href=""><FaChevronRight/>Terms & Condition</a>
            <a className="btn btn-link" href=""><FaChevronRight/>Support</a>
          </div> */}
          {/* <div className="col-lg-3 col-md-6">
            <h4 className="text-white mb-4">Newsletter</h4>
            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
            <div className="position-relative w-100">
              <input className="form-control bg-light border-light w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
              <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Footer;