import React from 'react';
import { Carousel, Row } from 'react-bootstrap';

const Products = () => {
  return (
    <section id="project-area" className="project-area pt-5">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-12">
            <h2 className="section-title">Work of Excellence</h2>
            <h3 className="section-sub-title">Our Products</h3>
          </div>
        </div>
        {/* <!--/ Title row end --> */}

        <Row>
          <div className="col-12">
            <Row>
              <div className="col-lg-4 col-md-6 shuffle-item" data-groups="[&quot;government&quot;,&quot;healthcare&quot;]">
                <div className="project-img-container carousel-custom">
                  <Carousel slide>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/2.5 strawberry/22.webp" alt="First slide" />
                    </Carousel.Item>
                  </Carousel>
                  <div className="project-item-info">
                    <div className="project-item-info-content">
                      <h3 className="project-item-title">
                        <a href="projects-single.html">2.5 Kg</a>
                      </h3>
                      <p className="project-cat">Strawberry</p>
                    </div>
                  </div>
                </div>
              </div>{/* <!-- shuffle item 1 end --> */}

              <div className="col-lg-4 col-md-6 shuffle-item" data-groups="[&quot;healthcare&quot;]">
                <div className="project-img-container carousel-custom">
                  <Carousel slide>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/4.5 grape/17.webp" alt="First slide" />
                    </Carousel.Item>
                  </Carousel>
                  <div className="project-item-info">
                    <div className="project-item-info-content">
                      <h3 className="project-item-title">
                        <a href="projects-single.html">4.5 Kg</a>
                      </h3>
                      <p className="project-cat">Grapes</p>
                    </div>
                  </div>
                </div>
              </div>{/* <!-- shuffle item 2 end --> */}

              <div className="col-lg-4 col-md-6 shuffle-item" data-groups="[&quot;infrastructure&quot;,&quot;commercial&quot;]">
                <div className="project-img-container carousel-custom">
                  <Carousel slide>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/5 kg grape/19.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/5 kg grape/20.webp" alt="First slide" />
                    </Carousel.Item>
                  </Carousel>
                  <div className="project-item-info">
                    <div className="project-item-info-content">
                      <h3 className="project-item-title">
                        <a href="projects-single.html">5 Kg</a>
                      </h3>
                      <p className="project-cat">Grapes</p>
                    </div>
                  </div>
                </div>
              </div>{/* <!-- shuffle item 3 end --> */}

              <div className="col-lg-4 col-md-6 shuffle-item" data-groups="[&quot;education&quot;,&quot;infrastructure&quot;]">
                <div className="project-img-container carousel-custom">
                  <Carousel slide>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/8 kg opentop/18.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/8 kg opentop/21.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/8 kg opentop/24.webp" alt="First slide" />
                    </Carousel.Item>
                  </Carousel>
                  <div className="project-item-info">
                    <div className="project-item-info-content">
                      <h3 className="project-item-title">
                        <a href="projects-single.html">Industrial</a>
                      </h3>
                      <p className="project-cat">Industrial</p>
                    </div>
                  </div>
                </div>
              </div>{/* <!-- shuffle item 4 end --> */}

              <div className="col-lg-4 col-md-6 shuffle-item" data-groups="[&quot;infrastructure&quot;,&quot;education&quot;]">
                <div className="project-img-container carousel-custom">
                  <Carousel slide>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/14 KG/14.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/12.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/13.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Cit Center.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Dakhlia red.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Dakhlia.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Dakhlia(1).webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Dole.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Egy Green.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Egyptian Citrus Craft.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Egyptian Citrus.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Fayoureze.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Nefertari.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/15 kg designs/Verona Citrus.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/8 kg opentop/18.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/8 kg opentop/21.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/8 kg opentop/24.webp" alt="First slide" />
                    </Carousel.Item>
                  </Carousel>
                  <div className="project-item-info">
                    <div className="project-item-info-content">
                      <h3 className="project-item-title">
                        <a href="projects-single.html">Individual Designs</a>
                      </h3>
                      <p className="project-cat">Designs</p>
                    </div>
                  </div>
                </div>
              </div>{/* <!-- shuffle item 5 end --> */}

              <div className="col-lg-4 col-md-6 shuffle-item" data-groups="[&quot;residential&quot;]">
                <div className="project-img-container carousel-custom">
                  <Carousel slide>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/Palet Cover/25.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/Palet Cover/26.webp" alt="First slide" />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img className="d-block w-100" src="img/packad/products/Palet Cover/27.webp" alt="First slide" />
                    </Carousel.Item>
                  </Carousel>
                  <div className="project-item-info">
                    <div className="project-item-info-content">
                      <h3 className="project-item-title">
                        <a href="projects-single.html">Palet Covers</a>
                      </h3>
                      <p className="project-cat">Industrial</p>
                    </div>
                  </div>
                </div>
              </div>{/* <!-- shuffle item 6 end --> */}
            </Row>{/* <!-- shuffle end --> */}

            {/* <div className="col-12">
              <div className="general-btn text-center">
                <a className="btn btn-primary" href="projects.html">View All Products</a>
              </div>
            </div> */}
             <div style={{ paddingBottom: '70px' }}></div>

          </div>{/* <!-- Content row end --> */}
        </Row>{/* <!--/ Container end --> */}
      </div>
      {/* <!-- Project area end --> */}
    </section>
  );
};

export default Products;
