import React, { useRef } from 'react';
import Carousel from './Components/Carousel';
import About from './Components/About';
import Facts from './Components/Facts';
import Team from './Components/Team';
import Companies from './Components/Companies';
import Footer from './Components/Footer';
import Header from './Components/Header';
import VideoPlayer from './Components/VideoPlayer';
import MissionandVision from './Components/MissionAndVision';

function Home() {
    const aboutRef = useRef(null);
    const factsRef = useRef(null);
    const featuresRef = useRef(null);
    const teamRef = useRef(null);
    const companiesRef = useRef(null);

    const scrollRefs = {
        aboutRef,
        factsRef,
        featuresRef,
        teamRef,
        companiesRef
    };

    const scrollToSection = (elementRef) => {
        window.scrollTo({
            top: elementRef.current.offsetTop,
            behavior: 'smooth',
        });
    };
    
    return (
        <>
            <Header scrollToSection={scrollToSection} scrollRefs={scrollRefs} />
            <Carousel />
            
            <div ref={scrollRefs.aboutRef}><About /></div>  
            <MissionandVision />
            <VideoPlayer videoSrc="/nvVid.mp4" />
            <div ref={scrollRefs.factsRef}><Facts /></div>
            {/* <div ref={scrollRefs.featuresRef}><Features /></div> */}
            <div ref={scrollRefs.teamRef}><Team /></div>
            <div ref={scrollRefs.companiesRef}><Companies /></div>
            <Footer />

        </>
    );
}

export default Home;
