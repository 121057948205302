
import product3 from "../img/product-3.png";
import product4 from "../img/product-4.png";
import product5 from "../img/product-5.png";
import product6 from "../img/product-6.jpg";

import nova1 from "../img/absorbing paper.jpeg";
import nova2 from "../img/cardboardcorners.jpeg";
import nova3 from "../img/carton bagpunnet.jpeg";
import nova4 from "../img/film flowpack.jpeg";
import nova5 from "../img/plastic punnet.jpeg";
import nova6 from "../img/plasticpunnet.jpeg";
import nova7 from "../img/stickers.jpeg";
import nova8 from "../img/wrapping paper.jpeg";
import nova9 from "../img/plasticpunnet2.jpeg";
export const PRODUCTS = [
  {
    id: 1,
    productName: "Absorbing Paper",
    
    productImage: nova1,
    category: "NovaPack Sud",
    // subsections: [
    //   {
    //     header: "Secret of Desert",
    //     // content: "High-quality feed, rich in nutrients.",
    //     isBold: true,
    //   },
    //   {
    //     header: "Cuf 101",
    //     // content: "Ideal for livestock feeding."
    //   }
    // ]
  },
  {
    id: 2,
    productName: "Strap and Cardboard Corners",
    productImage: nova2,
    category: "NovaPack Sud",
    // subsections: [
    //   {
    //     header: "WH 5012",
    //     // content: "Excellent source of fiber.",
    //     isBold: true,
    //   },
    //   {
    //     header: "5411",
    //     // content: "Available year-round."
    //   },
    //   {
    //     header:"5015"
    //   },
    //   {
    //     header:"7117"
    //   }
    // ]
  },
  {
    id: 3,
    productName: "CR-1",
    productImage: product3,
    category: "Sensitech",
   
  },
  {
    id: 4,
    productName: "Pergamine Paper",
    productImage: product4,
    category: "Proteku",
    // subsections: [
    //   {
    //     header: "Ananas",
    //     content: "Fakher , Shatha",
       
    //   },
    //   {
    //     header: "Italian Type",
    //     content: "Gharam"
    //   }
    // ]
  },
  {
    id: 5,
    productName: "TT Ultrafit",
    productImage: product5,
    category: "Sensitech",
    // subsections: [
    //   {
    //     header: "Mac G5",
    //     // content: "Sweet with a hint of musk.",
    //     isBold: true,
    //   },
    //   {
    //     header: "Dahab",
    //     // content: "Refrigerate after cutting."
    //   },
    //   {
    //     header: "G250",
    //   },
    //   {
    //     header: "G77",
    //   }
    // ]
  },
  {
    id: 6,
    productName: "TT RF",
    productImage: product6,
    category: "Sensitech",
    // subsections: [
    //   {
    //     header: "Lamaz",
    //     // content: "Grill, roast, or fry.",
    //     isBold: true,
    //   },
    //   {
    //     header: "Jiana",
    //     // content: "Goes well with garlic and tomatoes."
    //   }
    // ]
  },
  {
    id: 7,
    productName: "Carton Bags and Cardboard Punnet",
    productImage: nova3,
    category: "NovaPack Sud"
  },
  {
    id: 8,
    productName: "Film Flowpack",
    productImage: nova4,
    category: "NovaPack Sud"
  },
  {
    id: 12,
    productName: "Plastic Punnet",
    productImage: nova5,
    category: "NovaPack Sud"
  },
 
  {
    id: 9,
    productName: "Stickers",
    productImage: nova7,
    category: "NovaPack Sud"
  },
  {
    id: 11,
    productName: "Plastic Punnet",
    productImage: nova9,
    category: "NovaPack Sud"
  },
  {
    id: 10,
    productName: "Wrapping Paper",
    productImage: nova8,
    category: "NovaPack Sud"
  },
  
  
];
