import React from 'react';

const Facts = () => {
  const factsData = [
    { count: "1000+", text: "Happy Clients" },
    { count: "500+", text: "Projects Succeed" },
    { count: "11", text: "Awards Achieved" },
    { count: "82", text: "Team Members" }
  ];

  // Inline styles for text and description
  const headingStyle = {
    fontSize: '3rem', // Adjust as needed
    fontWeight: 'bold',
    lineHeight: '1.2',
  };

  const descriptionStyle = {
    fontSize: '1.25rem', // Adjust as needed
    lineHeight: '1.6',
  };

  const countStyle = {
    fontSize: '2.5rem', // Adjust as needed
    fontWeight: 'bold',
  };

  const textStyle = {
    fontSize: '1.125rem', // Adjust as needed
    fontWeight: '500',
    color: '#007bff', // Example primary color
  };

  return (
    <div className="container-fluid overflow-hidden my-5 px-lg-0">
      <div className="container facts px-lg-0">
        <div className="row g-0 mx-lg-0">
          <div className="col-lg-6 facts-text wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100 px-4 ps-lg-0">
              <h1 className="text-white mb-4" style={headingStyle}>NV Group</h1>
              <p className="text-light mb-5" style={descriptionStyle}>
                Demonstrated excellence and professionalism over the years with a diverse
                client base exceeding a thousand and numerous achievements.
              </p>
            </div>
          </div>
          <div className="col-lg-6 facts-counter wow fadeIn" data-wow-delay="0.5s">
            <div className="h-100 px-4 pe-lg-0">
              <div className="row g-5">
                {factsData.map((fact, index) => (
                  <div key={index} className="col-sm-6">
                    <h1 className="display-4" style={countStyle}>
                      {fact.count}
                    </h1>
                    <p style={textStyle}>{fact.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Facts;
