import React, { useRef } from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import { Outlet } from "react-router-dom";

function PackadMain(){
    const aboutRef = useRef(null);
    const homeRef = useRef(null);
    const productsRef = useRef(null);
    const facilitiesRef = useRef(null);

    const scrollRefs = {
        homeRef,
        aboutRef,
        productsRef,
        facilitiesRef
    };

    const scrollToSection = (elementRef) => {
        if (elementRef && elementRef.current) {
            window.scrollTo({
                top: elementRef.current.offsetTop,
                behavior: 'smooth',
            });
        }
    };


    return (
        <>
            <Header scrollRefs={scrollRefs} scrollToSection={scrollToSection} />
            <Outlet context={scrollRefs}></Outlet>
            <Footer />
        </>
    );
}

export default PackadMain;