import React from 'react';
import { FaCheck } from 'react-icons/fa';

const About = () => {
  return (
    <div className="container-fluid bg-light about pb-5">
      <div className="container pb-5">
        <div className="row g-5">
          <div className="col-xl-6 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="about-item-content bg-white rounded p-5 h-100">
              <h4 className="text-primary">About Our Company</h4>
              <h1 className="display-4 mb-4">Bridging Global Expertise and Local Markets</h1>
              <p>
                Nile Valley Trade & Agencies (NVT) is the cornerstone of NV Group, specializing in the import and export of packing and packaging materials and machines, particularly for fresh produce. As a representative of leading international manufacturers and businesses in the Middle East region, NVT plays a pivotal role in bridging global suppliers with local markets. Our strategic focus is on delivering tailored pricing and credit strategies that benefit our staff, customers, and suppliers alike. With a robust business plan that guides our operations, NVT ensures that our team is well-versed in understanding our products, partners, and market goals, enabling us to maintain a competitive edge in the industry.
              </p>
              <p className="text-dark">
  <FaCheck className="text-primary me-3" /> Tailored pricing for maximum value.
</p>
<p className="text-dark">
  <FaCheck className="text-primary me-3" /> Flexible credit for suppliers.
</p>
<p className="text-dark mb-4">
  <FaCheck className="text-primary me-3" /> Deep market expertise.
</p>

              <a className="btn btn-primary rounded-pill py-3 px-5" href="/">Check NV Group</a>
            </div>
          </div>
          <div className="col-xl-6 wow fadeInRight" data-wow-delay="0.2s">
            <div className="bg-white rounded p-5 h-100">
              <div className="row g-4 justify-content-center">
                <div className="col-12">
                  <div className="rounded bg-light">
                    <img src="img/Logos/nvt.png" className="img-fluid rounded w-100" alt="About" />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span className="text-primary fs-2 fw-bold">150</span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Global Suppliers Connected</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span className="text-primary fs-2 fw-bold">10</span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">International Manufacturers Agent</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span className="text-primary fs-2 fw-bold">21</span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Years Of Experience</h4>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="counter-item bg-light rounded p-3 h-100">
                    <div className="counter-counting">
                      <span className="text-primary fs-2 fw-bold">300</span>
                      <span className="h1 fw-bold text-primary">+</span>
                    </div>
                    <h4 className="mb-0 text-dark">Local Markets Served</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
