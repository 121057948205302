import React from "react";

export const ProductList = ({ products }) => {
  // Separate products into two categories
  const sensitechProducts = products.filter(product => product.category === "Sensitech");
  const protekuProducts = products.filter(product => product.category === "Proteku");

  return (
    <div className="product-list-container">
      {/* Render Sensitech Products */}
      {sensitechProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header text-center">Sensitech</h2>
          <div className="products">
            {sensitechProducts.map((product, index) => (
              <Product key={index} data={product} />
            ))}
          </div>
        </div>
      )}

      {/* Render Proteku Products */}
      {protekuProducts.length > 0 && (
        <div className="category-section">
          <h2 className="category-header text-center">Proteku</h2>
          <div className="products">
            {protekuProducts.map((product, index) => (
              <Product key={index} data={product} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export const Product = ({ data }) => {
  const { productName, productImage, subsections } = data;
  return (
    <div className="product">
      <div className="product-image">
        <img src={productImage} alt={productName} className="img-fluid" />
      </div>
      <div className="product-content">
        <h3 className="product-title text-center">{productName}</h3>
        {subsections && subsections.map((section, index) => (
          <div key={index} className="subsection">
            <h4 style={{ fontWeight: section.isBold ? "bold" : "normal" }}>
              {section.header}
            </h4>
            <p>{section.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};