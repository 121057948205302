import React from 'react';
import Carousel from './nvsComponents/Carousel';
import TopFeats from "./nvsComponents/TopFeats";
import Features from './nvsComponents/Features';
import Services from './nvsComponents/Services';
import Team from './nvsComponents/Team';
import About from './nvsComponents/About';
import { useOutletContext } from 'react-router-dom';

import { ProductList } from './nvsComponents/ProductList';


function NvsHome() {
    const scrollRefs = useOutletContext();
    return (
        <>
            <div ref={scrollRefs.homeRef}><Carousel /></div>
            <TopFeats />
            {/* <div ref={scrollRefs.aboutRef}><About /></div>
            <Facts /> */}
             {/* <About/> */}
            <div ref={scrollRefs.aboutRef}><Features /></div>
           
            <div ref={scrollRefs.servicesRef}><Services /></div>
            <ProductList/>
            <div ref={scrollRefs.teamRef}><Team /></div>
            
           
        </>
    );
    
    // return (
    //     <>
        
    //         <Carousel/>
    //         <TopFeats/>
    //         <About/>
    //         <Facts/>
    //         <Features/>
    //         <Services/>
    //         <Team/>


    //     </>
    // );
}

export default NvsHome;