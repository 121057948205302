import React, { useState} from 'react';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FaChevronDown, FaChevronUp, FaSlidersH, FaThumbsUp, FaTrophy, FaUsers } from 'react-icons/fa';

const About = () => {
    const [activeCardId, setActiveCardId] = useState(null);

    return (
        <section id="ts-features" className="ts-features mb-5">
            <Container>
                <Row>
                    <Col lg={6}>
                        <div className="ts-intro">
                            <h2 className="into-title">About Us</h2>
                            <h3 className="into-sub-title">Setting New Standards in Cardboard Packaging</h3>
                            <p>
                            PACKAD, the latest addition to the NV Group, is dedicated to delivering high-quality cardboard packaging solutions that meet and exceed global standards. Equipped with state-of-the-art machinery, including advanced die-cut and printing machines, PACKAD is capable of producing fully finished cardboard boxes tailored for industrial use. Our commitment to quality is reinforced by our extensive in-house laboratory, which houses cutting-edge equipment to test the durability and performance of our products. From food and beverages to pharmaceuticals and consumer goods, PACKAD’s packaging solutions are designed to meet the stringent demands of diverse industries, ensuring reliability and excellence in every product we deliver.
                            </p>
                        </div>
                        {/* Intro box end */}

                        <div className="gap-20"></div>

                        <Row>
                            <Col md={6}>
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <FaTrophy />
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">We've Repution for Excellence</h3>
                                    </div>
                                </div>{/* Service 1 end */}
                            </Col>{/* col end */}

                            <Col md={6}>
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <FaSlidersH />
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">We Build Partnerships</h3>
                                    </div>
                                </div>{/* Service 2 end */}
                            </Col>{/* col end */}
                        </Row>{/* Content row 1 end */}

                        <Row>
                            <Col md={6}>
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <FaThumbsUp />
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">Guided by Commitment</h3>
                                    </div>
                                </div>{/* Service 1 end */}
                            </Col>{/* col end */}

                            <Col md={6}>
                                <div className="ts-service-box">
                                    <span className="ts-service-icon">
                                        <FaUsers />
                                    </span>
                                    <div className="ts-service-box-content">
                                        <h3 className="service-box-title">A Team of Professionals</h3>
                                    </div>
                                </div>{/* Service 2 end */}
                            </Col>{/* col end */}
                        </Row>{/* Content row 1 end */}
                    </Col>{/* Col end */}

                    <Col lg={6} className='mt-4 mt-lg-0'>
                        <h3 className="into-sub-title">Our Values</h3>
                        <p>At PACKAD, we prioritize safety, excel in customer service, and uphold integrity in all our actions</p>
                        <Accordion id="our-values-accordion" className='accordion-group'>
                            <Card key={1}>
                                <Card.Header className='p-0 bg-transparent'>
                                    <CustomToggle cardId={1} setActiveCardId={setActiveCardId} activeCardId={activeCardId}  eventKey="0">Safety</CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>At PACKAD, safety is our cornerstone. We prioritize the well-being of our employees, customers, and the end-users of our products. Our stringent safety protocols ensure that every step of our manufacturing process adheres to the highest standards, resulting in packaging solutions that are both reliable and secure</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card key={2}>
                                <Card.Header className='p-0 bg-transparent'>
                                    <CustomToggle cardId={2} setActiveCardId={setActiveCardId} activeCardId={activeCardId} eventKey="1">Customer Service</CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="1">
                                    <Card.Body>We believe that exceptional customer service is key to building lasting partnerships. At PACKAD, we go the extra mile to understand and meet our customers' unique needs. From the initial consultation to final delivery, our team is dedicated to providing timely, responsive, and personalized support every step of the way </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                            <Card key={3}>
                                <Card.Header className='p-0 bg-transparent'>
                                    <CustomToggle cardId={3} setActiveCardId={setActiveCardId} activeCardId={activeCardId} eventKey="2">Integrity</CustomToggle>
                                </Card.Header>
                                <Accordion.Collapse eventKey="2">
                                    <Card.Body> Integrity is at the heart of everything we do. We are committed to maintaining the highest ethical standards, ensuring honesty and transparency in all our interactions. At PACKAD, we believe that trust is earned, and we work diligently to uphold our reputation for reliability and fairness</Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                        {/*/ Accordion end */}
                    </Col>{/* Col end */}
                </Row>{/* Row end */}
            </Container>{/* Container end */}
            {/* Feature are end */}</section>
    );
};

function CustomToggle({ children, eventKey, cardId, setActiveCardId, activeCardId }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        activeCardId === cardId ? setActiveCardId(null) : setActiveCardId(cardId)
    );

    return (
        <h2 className='mb-0'>
            <button
                className='btn btn-block text-start w-100 border-0'
                type="button"
                onClick={decoratedOnClick}
                aria-expanded={activeCardId === cardId}
            >
                {children}
                <div className='icon-holder'>
                {activeCardId === cardId ? <FaChevronUp className='icon' /> : <FaChevronDown className='icon' />}
                </div>
            </button>
        </h2>
    );
}

export default About;
