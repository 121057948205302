import React, { useEffect } from 'react';
import { Carousel as BootstrapCarousel } from 'bootstrap';

const Carousel = () => {
  useEffect(() => {
   
    const carouselElement = document.querySelector('#header-carousel');
    const carousel = new BootstrapCarousel(carouselElement, {
      interval: 5000, 
      wrap: true 
    });

   
    return () => {
      carousel.dispose();
    };
  }, []);

  return (
    <div className="container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
      <div id="header-carousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img className="w-100" src="img/NVGrouppics/backgroundtemp.webp" alt="NV Group background" fetchpriority='high'/>
            <div className="carousel-caption">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <h1 className="display-3 text-dark mb-4 animated slideInDown">
                    NV Group, Innovating Quality
                    </h1>
                    <p className="fs-5 text-body mb-5">
                    At NV Group, 'Innovating Quality, Cultivating Excellence' captures our dedication to innovation, quality, and excellence across packaging, export services, and agriculture.
                    </p>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img className="w-100" src="img/NVGrouppics/factory.jpg" alt="NV Group background 2" loading='lazy' fetchpriority='high'/>
            <div className="carousel-caption">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <h1 className="display-3 text-dark mb-4 animated slideInDown">
                  Building Tomorrow, Together
                    </h1>
                    <p className="fs-5 text-body mb-5">
                    At NV Group, 'Building Tomorrow, Together' embodies our commitment to collaborative innovation and sustainable growth across packaging, export services, and agriculture.
                    </p>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;