import React from 'react';
import Carousel from './Components/Carousel';
import About from './Components/About';
import Products from './Components/Products';
import Facilities from './Components/Facilities';
import Services from './Components/Services';
import Team from './Components/Team';
import Facts from './Components/Facts';
import Certs from './Components/Certs';
import { useOutletContext } from 'react-router-dom';

import VideoCarousel from './Components/VideoCarousel';

function PackadHome() {
    const scrollRefs = useOutletContext();
    const videos = [
        '/packadvid1.mp4',
        '/packadvid2.mp4',
        // Add more video URLs as needed
      ];
    return (
        <>
            <div ref={scrollRefs.homeRef}><Carousel /></div>
            <div ref={scrollRefs.aboutRef}><About /></div>
            <Services />
            <Facts />
           
            
            <VideoCarousel videos={videos} />
            <div ref={scrollRefs.facilitiesRef}><Facilities /></div>
            <div ref={scrollRefs.productsRef}><Products /></div>
            <Certs />
             <Team /> 
        </>
    );
}

export default PackadHome;
