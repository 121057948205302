  import React, { useState, useEffect } from 'react';
  import './HeaderCarousel.css'; // We'll create this CSS file for styling
  import { FaPlayCircle } from 'react-icons/fa';

  const HeaderCarousel = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const carouselItems = [
      {
        title: "Global Packaging Solutions",
        subtitle: "NVT’s Market Integration Expertise",
        description: "Nile Valley Trade & Agencies specializes in connecting global suppliers with local markets, offering tailored packaging solutions for fresh produce.",
        image: "img/carousel-2.png"
      },
      {
        title: "Strategic Imports & Exports",
        subtitle: "NVT’s Competitive Edge in Fresh Produce",
        description: "With a robust business plan and market expertise, NVT delivers strategic import and export services that benefit our partners and customers alike.",
        image: "img/carousel-2.png"
      }
    ];
    

    useEffect(() => {
      const interval = setInterval(() => {
        setActiveIndex((current) => 
          current === carouselItems.length - 1 ? 0 : current + 1
        );
      }, 7000); // Change slide every 5 seconds

      return () => clearInterval(interval);
    }, []);

    return (
      <div className="header-carousel">
        {carouselItems.map((item, index) => (
          <div 
            key={index} 
            className={`header-carousel-item bg-primary ${index === activeIndex ? 'active' : ''}`}
          >
            <div className="carousel-caption">
              <div className="container">
                <div className="row g-4 align-items-center">
                  <div className="col-lg-7 animated fadeInLeft">
                    <div className="text-sm-center text-md-start">
                      <h4 className="text-white text-uppercase fw-bold mb-4">{item.title}</h4>
                      <h1 className="display-1 text-white mb-4">{item.subtitle}</h1>
                      <p className="mb-5 fs-5">{item.description}</p>
                      <div className="d-flex justify-content-center justify-content-md-start flex-shrink-0 mb-4">
                        <a className="btn btn-light rounded-pill py-3 px-4 px-md-5 me-2" href="#"><FaPlayCircle className='me-2' /> Watch Video</a>
                        
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 animated fadeInRight">
                    <div className="carousel-img" style={{ objectFit: 'cover' }}>
                      <img src={item.image} className="img-fluid w-100" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  export default HeaderCarousel;