import React, { useState, useEffect } from 'react';
import { Nav, Navbar, Image, Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaEnvelope, FaFacebook, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaTwitter } from 'react-icons/fa';

const Header = ({ scrollToSection, scrollRefs }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavClick = (path, ref) => {
    if (location.pathname === path) {
      scrollToSection(ref);
    } else {
      navigate(path);
      setTimeout(() => scrollToSection(ref), 100);
    }
  };

  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowSpinner(false);
    }, 300); // 300 milliseconds = 0.3 seconds
    return () => clearTimeout(timer);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling
    });
  };

  return (
    <>
      {/* Spinner Start */}
      {showSpinner && (
        <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}></div>
        </div>
      )}
      {/* Spinner End */}
      <Container className="bg-light text-black-50 py-2 px-0 d-none d-lg-block" fluid>
        <Row className="gx-0 align-items-center">
          <Col lg={7} className="px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className='d-flex'><FaPhoneAlt className='me-2' /></small>
              <small>+010 93339559</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center me-4">
            <small className='d-flex'><FaEnvelope className='me-2' /></small>
            <small>messam@nvt.com.eg</small>
            </div>
          </Col>
          {/* <Col lg={5} className="px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center">
              <a className="text-black-50 ms-4 d-flex" href="#"><FaFacebook /></a>
              <a className="text-black-50 ms-4 d-flex" href="#"><FaTwitter /></a>
              <a className="text-black-50 ms-4 d-flex" href="#"><FaLinkedinIn /></a>
              <a className="text-black-50 ms-4 d-flex" href="#"><FaInstagram /></a>
            </div>
          </Col> */}
        </Row>
      </Container>
      <Navbar expand="lg" bg="primary" className='sticky-top px-lg-5 px-4'>
        <Navbar.Brand className='d-flex align-items-center' href="/">
          <h2 className="m-0 text-white">
            <Image className='me-3 bg-white rounded-5' src="img/Logos/packad.png" alt="" fluid />
            PACKAD
          </h2>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-secondary" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className='mx-auto pe-4 py-3 py-lg-0'>
            <Nav.Link className='nav-item' onClick={scrollToTop}>Home</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/packad', scrollRefs.aboutRef)}>About Us</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/packad', scrollRefs.facilitiesRef)}>Our Facilities</Nav.Link>
            <Nav.Link className='nav-item' onClick={() => handleNavClick('/packad', scrollRefs.productsRef)}>Our Products</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Header;