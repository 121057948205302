import React, { useLayoutEffect, useEffect, useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './animate.css';
import Home from './Pages/NV/Home';

import NvtMain from './Pages/NVT/NvtMain';
import NvtHome from './Pages/NVT/nvtHome';
import NvsMain from './Pages/NVS/NvsMain';
import NvsHome from './Pages/NVS/NvsHome';
import PackadMain from './Pages/PACKAD/PackadMain';
import PackadHome from './Pages/PACKAD/PackadHome';



import { loadCssFile } from './dynamicCss';

function useInstantScrollToTop() {
  const { pathname } = useLocation();
  const scrollingRef = useRef(false);

  useLayoutEffect(() => {
    // Disable smooth scrolling temporarily
    document.documentElement.style.scrollBehavior = 'auto';

    const handleScroll = () => {
      if (scrollingRef.current) {
        window.scrollTo(0, 0);
      }
    };

    const scrollToTop = () => {
      scrollingRef.current = true;
      window.scrollTo(0, 0);
      requestAnimationFrame(() => {
        scrollingRef.current = false;
      });
    };

    scrollToTop();
    window.addEventListener('scroll', handleScroll);

    // Re-enable smooth scrolling and remove the scroll listener
    return () => {
      document.documentElement.style.scrollBehavior = '';
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);
}

function App() {
  const location = useLocation();
  useInstantScrollToTop();

  useEffect(() => {
    const path = location.pathname;
    // Dynamically load CSS based on route prefixes
    if (path.startsWith('/packad')) {
      loadCssFile('./css/Packad.css');
    } else if (path.startsWith('/nvs')) {
      loadCssFile('./css/NVS.css');
    } else if(path.startsWith('/nvt')) {
      loadCssFile('./css/NVT.css')
    }else{
      loadCssFile('./css/NV.css');
    }

    const handleScroll = () => {
      const stickyElement = document.querySelector('.sticky-top');
      if (window.scrollY > 300) {
        stickyElement.classList.add('shadow-sm');
        stickyElement.style.top = '0px';
      } else {
        stickyElement.classList.remove('shadow-sm');
        stickyElement.style.top = '-100px';
      }
    };
    
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/nvt" element={<NvtMain />}>
          <Route index element={<NvtHome />} />
          
        </Route>
        <Route path="/nvs" element={<NvsMain />}>
          <Route index element={<NvsHome />} />
        
        </Route>
       
        <Route path="/packad" element={<PackadMain />}>
          <Route index element={<PackadHome />} />
          {/* <Route path="products" element={<ProductsPage />} />
          <Route path="facilities" element={<FacilitiesPage />} /> */}
        </Route>
      </Routes>
    </div>
  );
}

export default App;